import { useGetNewsCategoryHomePageQuery } from '@/generated/graphql';
import { ListNewsCategory } from '.';
import { NewsHomepageParams } from '@/constants/defaultParams';

const NewsSection = () => {
  const { data: listNewsCategory, loading } = useGetNewsCategoryHomePageQuery({
    variables: NewsHomepageParams,
  });

  if (loading) {
    return <></>;
  }

  return (
    <>
      <div className="container flex flex-col">
        {listNewsCategory?.news_category?.map((newsCategory, index) => {
          return <ListNewsCategory key={index} {...newsCategory} />;
        })}
      </div>
    </>
  );
};

export default NewsSection;
